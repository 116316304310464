import request from '@/utils/request'
import {objectToQueryString} from "@/utils/utils";

// 获取今日充值
export function getTodayRechart(permissionId) {
    return request.get('/HomesController/getTodayRecharge?permissionId=' + permissionId);
}

// 首页-获取收入统计
export function getEarningList(body){
    return request.post('/base/earningList?'+objectToQueryString(body));
}

// 首页-获取车辆用户分析
export function getVehicleAnalisys(body){
    return request.post('/base/vehicleAnalisys?'+objectToQueryString(body));
}

// 首页-获取综合分析
export function searchParkingLotAnalyze(body){
    return request.post('/base/searchParkingLotAnalyze?'+objectToQueryString(body));
}

// 车场分析、泊位分析数据
export function getComprehensiveAnalysis(){
    return request.post('/base/ComprehensiveAnalysis');
}

// 车场分析
export function getParkingLotTotal(body){
    return request.post('/base/getParkingLotTotal?'+objectToQueryString(body));
}

// 泊位数统计
export function getParkingSpaceTotal(body){
    return request.post('/base/getParkingSpaceTotal?'+objectToQueryString(body));
}

// 昨日车位利用率
export function searchParkingSpaceAnalyze(body){
    return request.post('/base/searchParkingSpaceAnalyze?'+ objectToQueryString(body));
}

// 进出场流量分析
export function searchParkingOrderAnalyze(body){
    return request.post('/base/searchParkingOrderAnalyze?' + objectToQueryString(body))
}

export function searchParkingFeeChangeAnalyze(body) {
    return request.post('/base/searchParkingFeeChangeAnalyze?' + objectToQueryString(body))
}

export function getParkingLotRevenueSort(body){
    return request.post(`/base/parkingLotRevenueSort?` + objectToQueryString(body));
}

/**
 * 获取当天收入
 * @returns
 */
export function getTodayIncome(permissionId) {
    return request.get('/HomesController/getTodayIncome?permissionId=' + permissionId);
}

export function getStatics() {
    return request.get('/HomesController/getStatics')
}

export function deviceAnalysis(body) {
    return request.post('/base/deviceAnalysis?'+objectToQueryString(body))
}

export function findParkingSpaceUtilizationAndTurnover(permissionId) {
    return request.get('/HomesController/findParkingSpaceUtilizationAndTurnover?permissionId=' + permissionId)
}

export function findMonthIncome(permissionId) {
    return request.get('/HomesController/findMonthIncome?permissionId=' + permissionId)
}


export function findMonthParkingSpaceUtilizationAndTurnover(permissionId) {
    return request.get('/HomesController/findMonthParkingSpaceUtilizationAndTurnover?permissionId=' + permissionId)
}

export function findMonthIncomeForRegion(permissionId) {
    return request.get('/HomesController/findMonthIncomeForRegion?permissionId=' + permissionId)
}
